import React, { createContext, useState, useEffect } from "react";

export const HistoricalDataContext = createContext();

export const HistoricalDataProvider = ({ children }) => {
    const [data, setData] = useState([]);
    const [funds, setFunds] = useState([]);
    const [selectedFund, setSelectedFund] = useState("");
    const currentDate = new Date().toISOString().slice(0, 10); // Current date in YYYY-MM-DD
    const fifteenDaysAgo = new Date(Date.now() - 10 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10); // 15 days before

    const [startDate, setStartDate] = useState(fifteenDaysAgo);
    const [endDate, setEndDate] = useState(currentDate);
    const [historicalData, setHistoricalData] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            try {
                const response = await fetch("https://trium-server.azurewebsites.net/api/sql-query", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ sql_query: "SELECT * FROM dbo.dummydata;" }),
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }

                const jsonData = await response.json();
                console.log(jsonData);
                setData(jsonData);

                const uniqueFunds = [...new Set(jsonData.map((item) => item.FUND))];
                setFunds(uniqueFunds);
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };

        loadData();
    }, []);

    useEffect(() => {
        if (!selectedFund || !startDate || !endDate) {
            setHistoricalData([]);
            return;
        }
    
        // Filter the data by selected fund and date range
        const filteredData = data.filter(item => {
            const itemDate = new Date(item.DATE).toISOString().slice(0, 10);
            return (
                item.FUND === selectedFund &&
                itemDate >= startDate &&
                itemDate <= endDate
            );
        });
    
        // Aggregate data by date
        const aggregatedData = Object.values(
            filteredData.reduce((acc, item) => {
                const dateKey = new Date(item.DATE).toISOString().slice(0, 10); // Normalize date
    
                if (!acc[dateKey]) {
                    acc[dateKey] = {
                        date: dateKey,
                        cpExposure: 0,
                        limit: 0,
                        marginExcess: 0,
                        marginRequirement: 0,
                        marginBalance: 0,
                    };
                }
    
                // Aggregate metrics (sum values for simplicity)
                acc[dateKey].cpExposure += item.CP_EXPOSURE || 0;
                acc[dateKey].limit += item.LIMIT || 0;
                acc[dateKey].marginExcess += item.MARGIN_EXCESS || 0;
                acc[dateKey].marginRequirement += item.MARGIN_REQUIREMENT || 0;
                acc[dateKey].marginBalance += item.MARGIN_BALANCE || 0;
    
                return acc;
            }, {})
        );
    
        // Sort aggregated data by date
        const sortedData = aggregatedData.sort((a, b) => new Date(a.date) - new Date(b.date));
    
        setHistoricalData(sortedData);
    }, [selectedFund, startDate, endDate, data]);

    return (
        <HistoricalDataContext.Provider
            value={{
                funds,
                selectedFund,
                setSelectedFund,
                startDate,
                setStartDate,
                endDate,
                setEndDate,
                historicalData,
            }}
        >
            {children}
        </HistoricalDataContext.Provider>
    );
};