import React, { useState, useEffect, useRef } from 'react';
import './Chatbot.css';
import axios from 'axios';

const Chatbot = () => {
    const [messages, setMessages] = useState(() => {
        const savedMessages = localStorage.getItem('chatbotMessages');
        return savedMessages ? JSON.parse(savedMessages) : [];
    });
    const [userInput, setUserInput] = useState('');
    const [isTyping, setIsTyping] = useState(false);

    const chatWindowRef = useRef(null); // Ref to handle scrolling

    useEffect(() => {
        localStorage.setItem('chatbotMessages', JSON.stringify(messages));
        scrollToBottom(); // Scroll to the bottom whenever messages change
    }, [messages]);

    const scrollToBottom = () => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    };

    const sendMessage = async () => {
        if (!userInput.trim()) return;

        const userMessage = { sender: 'user', text: userInput };
        const MAX_HISTORY = 20; // Keep the last 20 messages
        const trimmedMessages = messages.slice(-MAX_HISTORY);
        const conversationHistory = trimmedMessages.map((msg) => ({
            role: msg.sender === 'user' ? 'user' : 'assistant',
            content: msg.text,
        }));

        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setUserInput('');
        setIsTyping(true);

        try {
            const response = await axios.post('https://trium-server.azurewebsites.net/api/chatbot', {
                user_message: userInput,
                conversation_history: conversationHistory,
            });

            const botMessage = { sender: 'bot', text: response.data.reply };
            setMessages((prevMessages) => [...prevMessages, botMessage]);
        } catch (error) {
            const errorMessage = { sender: 'bot', text: 'Sorry, something went wrong. Please try again later.' };
            setMessages((prevMessages) => [...prevMessages, errorMessage]);
        } finally {
            setIsTyping(false);
        }
    };

    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter') sendMessage();
    };

    const clearConversation = async () => {
        try {
            await axios.post('https://trium-server.azurewebsites.net/api/clear_memory');
            setMessages([]);
            localStorage.removeItem('chatbotMessages');
        } catch (error) {
            console.error('Failed to clear memory on backend:', error);
        }
    };

    const renderMessage = (text) => {
        const formattedText = text
            .replace(/\n/g, '<br>') // Replace \n with HTML line breaks
            .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>'); // Replace **bold** with <strong>
        return { __html: formattedText };
    };

    return (
        <div className="chatbot-container">
            {messages.length === 0 && (
                <div className="static-placeholder">
                    Ask me anything!
                </div>
            )}
            <div className="chat-window" ref={chatWindowRef}>
                {messages.map((msg, index) => (
                    msg.sender === 'user' ? (
                        <div
                            key={index}
                            className="message user-message"
                        >
                            {msg.text}
                        </div>
                    ) : (
                        <div
                            key={index}
                            className="message bot-message"
                            dangerouslySetInnerHTML={renderMessage(msg.text)}
                        />
                    )
                ))}
                {isTyping && (
                    <div className="message bot-message typing-indicator">
                        <span></span><span></span><span></span>
                    </div>
                )}
            </div>
            <div className="input-container">
                <input
                    type="text"
                    placeholder="Type your message..."
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyPress={handleInputKeyPress}
                    className="chat-input"
                />
                <button onClick={sendMessage} className="send-button">
                    Send
                </button>
                <button onClick={clearConversation} className="clear-button">
                    X
                </button>
            </div>
        </div>
    );
};

export default Chatbot;