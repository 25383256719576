import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import StatusPage from "./components/Pages/Dashboard/Dashboard";
import RequirementsPage from "./components/Pages/Requirements/RequirementsPage";
import HistoricalsPageWrapper from "./components/Pages/Historicals/HistoricalsPage";
import DocHistory from "./components/Pages/DocumentHistory/DocHist";
import Chatbot from "./components/Pages/Chatbot/Chatbot"; // Import the Chatbot component
import Login from "./components/Login/Login";
import LandingPage from "./components/LandingPage/LandingPage";
import ModelingPageWithProvider from "./components/Pages/ModelingPage/ModelingPage";
import './App.css';

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

    const toggleSidebar = () => setIsSidebarCollapsed(!isSidebarCollapsed);

    const handleLogout = () => {
        localStorage.removeItem('token'); // Clear token from local storage
        setIsAuthenticated(false); // Update authentication state
    };

    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                {isAuthenticated ? (
                    <Route path="/*" element={
                        <div className="app">
                            <Sidebar 
                                isSidebarCollapsed={isSidebarCollapsed} 
                                toggleSidebar={toggleSidebar} 
                                handleLogout={handleLogout} 
                            />
                            <div className={`main-content ${isSidebarCollapsed ? 'collapsed' : ''}`}>
                                <Routes>
                                    <Route path="dashboard" element={<StatusPage />} />
                                    <Route path="requirements" element={<RequirementsPage />} />
                                    <Route path="historicals" element={<HistoricalsPageWrapper />} />
                                    <Route path="dochist" element={<DocHistory />} />
                                    <Route path="chatbot" element={<Chatbot />} /> 
                                    <Route path="modeling" element={<ModelingPageWithProvider />} /> 
                                </Routes>
                            </div>
                        </div>
                    } />
                ) : (
                    <Route path="*" element={<Navigate to="/" replace />} />
                )}
            </Routes>
        </Router>
    );
};

export default App;