import React, { createContext, useContext, useState, useEffect } from "react";
import "./ModelingPage.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
} from "recharts";

// Create a context within ModelingPage
const FundContext = createContext();

const FundProvider = ({ children }) => {
  const [funds, setFunds] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://trium-server.azurewebsites.net/api/sql-query", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ sql_query: "SELECT * FROM dbo.dummydata;" }),
        });

        const result = await response.json();
        setData(result);

        const uniqueFunds = [...new Set(result.map((item) => item.FUND))];
        setFunds(uniqueFunds);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <FundContext.Provider value={{ funds, data }}>
      {children}
    </FundContext.Provider>
  );
};

const ModelingPage = () => {
  const { funds, data } = useContext(FundContext);
  const [portfolioMetrics, setPortfolioMetrics] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data.length > 0) {
      const metrics = funds.map((fund) => {
        const fundData = data.filter((item) => item.FUND === fund);

        // Dummy projected cash flow values
        const projectedCashInflows = 500000; // Example synthetic data
        const projectedCashOutflows = 400000; // Example synthetic data

        // NAV and returns
        const navs = fundData.map((item) => item.NAV);
        const returns = navs.slice(1).map((nav, idx) => (nav - navs[idx]) / navs[idx]);

        const meanReturn = returns.reduce((sum, r) => sum + r, 0) / returns.length || 0;
        const stdDev = Math.sqrt(
          returns.reduce((sum, r) => sum + Math.pow(r - meanReturn, 2), 0) /
            (returns.length - 1 || 1)
        );

        const downsideDeviation = Math.sqrt(
          returns
            .filter((r) => r < 0)
            .reduce((sum, r) => sum + Math.pow(r, 2), 0) /
            (returns.length || 1)
        );

        const drawdowns = navs.map((nav, idx) => {
          const peak = Math.max(...navs.slice(0, idx + 1));
          return (peak - nav) / peak;
        });
        const maxDrawdown = Math.max(...drawdowns);

        return {
          fund,
          "Average Leverage Ratio":
            fundData.reduce((sum, item) => sum + (item.CP_EXPOSURE / item.NAV || 0), 0) /
            fundData.length,
          "Cash Flow Ratio": projectedCashInflows / projectedCashOutflows,
          "Debt-to-Asset Ratio":
            fundData.reduce((sum, item) => sum + (item.CP_EXPOSURE / item.NAV || 0), 0) /
            fundData.length,
          "Capital Efficiency": projectedCashInflows / (fundData[0]?.NAV || 1),
          "Stress-Adjusted Liquidity":
            fundData.reduce(
              (sum, item) =>
                sum +
                (((item.CASH_BALANCE * 0.7) + item.NON_PLEDGED_BONDS) /
                  item.MARGIN_REQUIREMENT || 0),
              0
            ) / fundData.length,
          "Sharpe Ratio": (meanReturn - 0.02 / 252) / (stdDev || 1),
          "Sortino Ratio": (meanReturn - 0.02 / 252) / (downsideDeviation || 1),
          "Drawdown": maxDrawdown,
        };
      });

      setPortfolioMetrics(metrics);
      setLoading(false);
    }
  }, [data, funds]);

  if (loading) {
    return <div className="loading-screen">Calculating Metrics...</div>;
  }

  return (
    <div className="modeling-page">
      <h1>Comprehensive Portfolio Metrics</h1>

      {/* Metrics Table */}
      <div className="metrics-table">
        <table>
          <thead>
            <tr>
              <th>Fund</th>
              <th>Average Leverage Ratio</th>
              <th>Cash Flow Ratio</th>
              <th>Debt-to-Asset Ratio</th>
              <th>Capital Efficiency</th>
              <th>Stress-Adjusted Liquidity</th>
              <th>Sharpe Ratio</th>
              <th>Sortino Ratio</th>
              <th>Drawdown</th>
            </tr>
          </thead>
          <tbody>
            {portfolioMetrics.map((metric, index) => (
              <tr key={index}>
                <td>{metric.fund}</td>
                <td>{metric["Average Leverage Ratio"]?.toFixed(2) || "N/A"}</td>
                <td>{metric["Cash Flow Ratio"]?.toFixed(2) || "N/A"}</td>
                <td>{metric["Debt-to-Asset Ratio"]?.toFixed(2) || "N/A"}</td>
                <td>{metric["Capital Efficiency"]?.toFixed(2) || "N/A"}</td>
                <td>{metric["Stress-Adjusted Liquidity"]?.toFixed(2) || "N/A"}</td>
                <td>{metric["Sharpe Ratio"]?.toFixed(2) || "N/A"}</td>
                <td>{metric["Sortino Ratio"]?.toFixed(2) || "N/A"}</td>
                <td>{metric.Drawdown?.toFixed(2) || "N/A"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Charts */}
      <div className="charts-container">
        <div className="bar-chart-container">
            <h3 className="chart-title">Risk-Adjusted Metrics</h3>
            <BarChart width={600} height={400} data={portfolioMetrics}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="fund" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Sharpe Ratio" fill="#8884d8" />
                <Bar dataKey="Sortino Ratio" fill="#82ca9d" />
            </BarChart>
        </div>
        <div className="pie-chart-container">
            <PieChart width={500} height={500}>
                <Pie
                    data={portfolioMetrics}
                    dataKey="Stress-Adjusted Liquidity"
                    nameKey="fund"
                    cx="50%"
                    cy="50%"
                    outerRadius={150}
                    fill="#8884d8"
                    label
                    >
                    {portfolioMetrics.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={index % 2 === 0 ? "#82ca9d" : "#ffc658"} />
                    ))}
                </Pie>
                <Tooltip />
            </PieChart>
        </div>
      </div>
    </div>
  );
};

const ModelingPageWithProvider = () => (
    <FundProvider>
      <ModelingPage />
    </FundProvider>
  );
  
export default ModelingPageWithProvider