// src/components/pages/Historicals/HistoricalsPageContent.js
import React, { useContext } from "react";
import { HistoricalDataContext } from "./HistoricalsDataContext";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import "./HistoricalsPage.css";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const HistoricalsPageContent = () => {
    const { funds, selectedFund, setSelectedFund, startDate, setStartDate, endDate, setEndDate, historicalData } = useContext(HistoricalDataContext);

    const dates = historicalData?.map(item => item.date || "");
    const cpExposureData = historicalData?.map(item => item.cpExposure || 0);
    const limitData = historicalData?.map(item => item.limit || 0);
    const marginExcessData = historicalData?.map(item => item.marginExcess || 0);
    const marginRequirementData = historicalData?.map(item => item.marginRequirement || 0);
    const marginBalanceData = historicalData?.map(item => item.marginBalance || 0);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top",
            },
        },
        scales: {
            x: { type: 'category' },
            y: { type: 'linear' }
        }
    };

    const cpExposureLimitChart = {
        labels: dates,
        datasets: [
            {
                label: "CP_EXPOSURE",
                data: cpExposureData,
                borderColor: "rgba(75, 192, 192, 1)",
                fill: false,
            },
            {
                label: "Limit",
                data: limitData,
                borderColor: "rgba(255, 99, 132, 1)",
                fill: false,
            },
        ],
    };

    const marginDataChart = {
        labels: dates,
        datasets: [
            {
                label: "Margin Excess",
                data: marginExcessData,
                borderColor: "rgba(54, 162, 235, 1)",
                fill: false,
            },
            {
                label: "Margin Requirement",
                data: marginRequirementData,
                borderColor: "rgba(255, 206, 86, 1)",
                fill: false,
                hidden: true,
            },
            {
                label: "Margin Balance",
                data: marginBalanceData,
                borderColor: "rgba(153, 102, 255, 1)",
                fill: false,
                hidden: true,
            },
        ],
    };

    return (
        <div className="historicals-page">
            <div className="filters">
                <label>
                    Fund:
                    <select value={selectedFund} onChange={e => setSelectedFund(e.target.value)}>
                        <option value="">Select Fund</option>
                        {funds.map(fund => (
                            <option key={fund} value={fund}>{fund}</option>
                        ))}
                    </select>
                </label>
                <label>
                    Start Date:
                    <input
                        type="date"
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                    />
                </label>
                <label>
                    End Date:
                    <input
                        type="date"
                        value={endDate}
                        onChange={e => setEndDate(e.target.value)}
                    />
                </label>
            </div>
            <section className="data-table">
                <h2>Historical Data</h2>
                {selectedFund ? (
                    <div className="graph-container">
                        <div className="chart">
                            <Line data={cpExposureLimitChart} options={options} />
                        </div>
                        <div className="chart">
                            <Line data={marginDataChart} options={options} />
                        </div>
                    </div>
                ) : (
                    <p>Please select a fund and date range to view historical data.</p>
                )}
            </section>
        </div>
    );
};

export default HistoricalsPageContent;