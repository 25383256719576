// src/components/Sidebar/Sidebar.js
import React from "react";
import { NavLink } from "react-router-dom";
import "./Sidebar.css";

const Sidebar = ({ isSidebarCollapsed, toggleSidebar, handleLogout }) => {
    return (
        <aside className={`sidebar ${isSidebarCollapsed ? "collapsed" : ""}`}>
            <button 
                className="toggle-btn"
                onClick={toggleSidebar}
            >
                {isSidebarCollapsed ? ">" : "<"}
            </button>
            {!isSidebarCollapsed && (
                <>
                    <h2>stag</h2>
                    <nav>
                        <NavLink to="/dashboard" className={({ isActive }) => isActive ? "active" : ""}>
                            Dashboard
                        </NavLink>
                        <NavLink to="/requirements" className={({ isActive }) => isActive ? "active" : ""}>
                            Requirements
                        </NavLink>
                        <NavLink to="/historicals" className={({ isActive }) => isActive ? "active" : ""}>
                            Historicals
                        </NavLink>
                        <NavLink to="/modeling" className={({ isActive }) => isActive ? "active" : ""}>
                            Models
                        </NavLink>
                        <NavLink to="/dochist" className={({ isActive }) => isActive ? "active" : ""}>
                            Documents
                        </NavLink>
                        <NavLink to="/chatbot" className={({ isActive }) => isActive ? "active" : ""}>
                            Chatbot
                        </NavLink>
                    </nav>
                    <button className="logout-btn" onClick={handleLogout}>Logout</button>
                </>
            )}
        </aside>
    );
};

export default Sidebar;